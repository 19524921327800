import { authRequest } from "./base";

export const authAPI = {
  login: (data: any) => {
    return authRequest("/auth/user/login", {
      method: "POST",
      data,
    });
  },
  logout: () => {
    return authRequest("/auth/logout", {
      method: "POST",
    });
  },
  register: (data: any) => {
    return authRequest("/auth/register", {
      method: "POST",
      data,
    });
  },
  checkSession: () => {
    return authRequest("/auth/check-session", {
      method: "POST",
    });
  },
};
