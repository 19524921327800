import React, { useEffect } from "react";
import ErrorBoundary from "pages/App/subcomponents/ErrorBoundary";
import publicRoutes from "pages/routes/route.public";
import { FC, Suspense } from "react";
import Loading from "components/Loading";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { NOT_FOUND, NEW_ORDER, LOGIN } from "pages/routes/route.constant";
import PageNotFound from "pages/PageNotFound";
import authRoutes from "pages/routes/route.auth";
import MainLayout from "pages/App/subcomponents/MainLayout";
import { useDispatch } from "react-redux";
import { authAPI } from "api/auth";
import { logout } from "./store/appSlice";
import { message } from "antd";
import { GetUserName } from "utils/user";

const App = () => {
  const homeRoute = process.env.REACT_APP_HOME_PAGE_ROUTE || NEW_ORDER;

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const currentURL = window.location.href;

  useEffect(() => {
    const username = GetUserName();
    if (!username && !currentURL.includes(LOGIN)) {
      navigate(LOGIN);
    }
  }, []);

  useEffect(() => {
    const checkSession = async () => {
      try {
        const response = await authAPI.checkSession();
        if (response?.data?.result?.loggedIn) {
          return;
        }
        dispatch(logout());
        localStorage.clear();
        navigate(LOGIN);
        message.info("Session timeout");
      } catch (err) {
        dispatch(logout());
        localStorage.clear();
        navigate(LOGIN);
        message.info("Session timeout");
      }
    };
    const intervalId: any = setInterval(checkSession, 60 * 1000 * 10);

    return () => clearInterval(intervalId);
  }, [navigate]);

  return (
    <ErrorBoundary>
      <Routes>
        {publicRoutes.map(({ path, element }) => {
          const Element: FC = element;
          return (
            <Route
              key={path}
              path={path}
              element={
                <Suspense fallback={<Loading />}>
                  {/* <UnauthenticatedTemplate>
                      <Element />
                    </UnauthenticatedTemplate> */}
                  <Element />
                </Suspense>
              }
            />
          );
        })}
        <Route element={<MainLayout />}>
          <Route path="/" element={<Navigate to={homeRoute} />} />
          {authRoutes.map(({ path, element }) => {
            const Element: FC = element;
            return (
              <Route
                key={path}
                path={path}
                element={
                  <Suspense fallback={<Loading />}>
                    <Element />
                  </Suspense>
                }
              />
            );
          })}
        </Route>
        <Route
          key={NOT_FOUND}
          path={NOT_FOUND}
          element={
            <Suspense fallback={<Loading />}>
              {/* <UnauthenticatedTemplate>
                  <PageNotFound />
                </UnauthenticatedTemplate> */}
              <PageNotFound />
            </Suspense>
          }
        />
      </Routes>
    </ErrorBoundary>
  );
};

export default App;
